import { Link } from 'gatsby'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Popup from 'reactjs-popup'
import Menu from 'react-burger-menu/lib/menus/slide'
import LocalizedLink from '../LocalizedLink'
import logo from './ATPLogo.png'
import styles from './index.module.css'

const menuStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '16px',
    height: '16px',
    right: '16px',
    top: '16px'
  },
  bmMenuWrap: {
    top: '0px'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenu: {
    background: '#fff',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#fff'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'block',
    marginBottom: '20px',
    textDecoration: 'none',
    color: '#000'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

const Header = ({ intl: { locale }, location }) => (
  <div className={styles.header}>
    <div className={styles.inner}>
      <h1 style={{ margin: 0 }}>
        <LocalizedLink to="/">
          <img src={logo} />
        </LocalizedLink>
      </h1>

      <nav className={styles.navbar}>
        <LocalizedLink to="/" activeStyle={{ borderTop: 'solid 3px #6F4A92', paddingTop: 17 }}>
          <FormattedMessage id="mainPage" />
        </LocalizedLink>
        <LocalizedLink to="/protocol" className={styles.navLink} activeStyle={{ borderTop: 'solid 3px #6F4A92', paddingTop: 17 }}>
          <FormattedMessage id="Protocol" />
        </LocalizedLink>
        <Popup
          trigger={
            <LocalizedLink
              to="/product"
              className={styles.navLink}
              activeStyle={{ borderTop: 'solid 3px #6F4A92', paddingTop: 17 }}
            >
              <FormattedMessage id="productModule" />
            </LocalizedLink>
          }
          position="bottom left"
          on="hover"
          closeOnDocumentClick
          offsetY={18}
          offsetX={-10}
          mouseLeaveDelay={100}
          mouseEnterDelay={0}
          contentStyle={{ width: 250, padding: '0px', border: 'none' }}
          arrow={false}
        >
          <div className="menu">
            <div className={styles.menuItem}>
              <LocalizedLink to="/product#sdk">
                <FormattedMessage id="product.sdk.title" />
              </LocalizedLink>
            </div>
            <div className={styles.menuItem}>
              <LocalizedLink to="/product#platform">
                <FormattedMessage id="product.platform.title" />
              </LocalizedLink>
            </div>
            <div className={styles.menuItem}>
              <LocalizedLink to="/product#analysis">
                <FormattedMessage id="product.analysis.title" />
              </LocalizedLink>
            </div>
          </div>
        </Popup>
        {/* <LocalizedLink
          to="/product"
          activeStyle={{ borderTop: 'solid 3px #6F4A92', paddingTop: 17 }}
        >
          <FormattedMessage id="productModule" />
        </LocalizedLink> */}
        <Popup
          trigger={
            <LocalizedLink
              to="/solution"
              className={styles.navLink}
              activeStyle={{ borderTop: 'solid 3px #6F4A92', paddingTop: 17 }}
            >
              <FormattedMessage id="solution" />
            </LocalizedLink>
          }
          position="bottom left"
          on="hover"
          closeOnDocumentClick
          offsetY={18}
          offsetX={-10}
          mouseLeaveDelay={100}
          mouseEnterDelay={0}
          contentStyle={{ width: 200, padding: '0px', border: 'none' }}
          arrow={false}
        >
          <div className="menu">
            <div className={styles.menuItem}>
              <LocalizedLink to="/solution#onchain">
                <FormattedMessage id="solution.onChainVoting.title" />
              </LocalizedLink>
            </div>
            <div className={styles.menuItem}>
              <LocalizedLink to="/solution#smartdrop">
                <FormattedMessage id="solution.smartdrop.title" />
              </LocalizedLink>
            </div>
            <div className={styles.menuItem}>
              <LocalizedLink to="/solution#marketing">
                <FormattedMessage id="solution.smartdrop.title1" />
              </LocalizedLink>
            </div>
            <div className={styles.menuItem}>
              <LocalizedLink to="/solution#marketing">
                <FormattedMessage id="solution.smartdrop.title2" />
              </LocalizedLink>
            </div>
          </div>
        </Popup>
        {locale === 'en' ? (
          <a
            className={styles.navLink}
            href="https://docs.google.com/forms/d/e/1FAIpQLSfGD8b0hneADbfIp8OhQzO1dJaMJOe1vqMlS2NY7f9cmqGJAg/viewform?usp=sf_link"
            target="blank"
          >
            FREE TRIAL
          </a>
        ) : (
          <a className={styles.navLink} href="http://atlaspio.mikecrm.com/0uJEmbE" target="blank">
            申请试用
          </a>
        )}
        {/* <LocalizedLink
          to="/application"
          className={styles.navLink}
          activeStyle={{ borderTop: 'solid 3px #6F4A92', paddingTop: 17 }}
        >
          <FormattedMessage id="applyForTrial" />
        </LocalizedLink> */}
        <LocalizedLink
          to="/join"
          className={styles.navLink}
          activeStyle={{ borderTop: 'solid 3px #6F4A92', paddingTop: 17 }}
        >
          <FormattedMessage id="joinTeam" />
        </LocalizedLink>
        <LocalizedLink
          to="/about"
          className={styles.navLink}
          activeStyle={{ borderTop: 'solid 3px #6F4A92', paddingTop: 17 }}
        >
          <FormattedMessage id="aboutUs" />
        </LocalizedLink>
        {locale === 'zh' ? (
          <Link to={location.pathname.replace('/zh', '')} className={styles.navLink}>
            En
          </Link>
        ) : (
          <Link to={'zh'.concat(location.pathname)} className={styles.navLink}>
            中文
          </Link>
        )}
        {/* {Object.keys(locales).map(key => (
          <Link
            className={key === locale ? styles.active : ''}
            key={locales[key].locale}
            to={locales[key].default ? '/' : `/${locales[key].path}`}
          >
            {locales[key].locale}
          </Link>
        ))} */}
      </nav>
      <div className={styles.sidemenu}>
        <Menu right styles={menuStyles} width={200}>
          <LocalizedLink to="/">
            <FormattedMessage
              id="mainPage"
              activeStyle={{ borderLeft: 'solid 3px #6F4A92', paddingLeft: 10 }}
            />
          </LocalizedLink>
          <LocalizedLink
            to="/protocol"
            activeStyle={{ borderLeft: 'solid 3px #6F4A92', paddingLeft: 10 }}
          >
            <FormattedMessage id="Protocol" />
          </LocalizedLink>
          <LocalizedLink
            to="/product"
            activeStyle={{ borderLeft: 'solid 3px #6F4A92', paddingLeft: 10 }}
          >
            <FormattedMessage id="productModule" />
          </LocalizedLink>
          <LocalizedLink
            to="/solution"
            activeStyle={{ borderLeft: 'solid 3px #6F4A92', paddingLeft: 10 }}
          >
            <FormattedMessage id="solution" />
          </LocalizedLink>
          <LocalizedLink
            to="/join"
            activeStyle={{ borderLeft: 'solid 3px #6F4A92', paddingLeft: 10 }}
          >
            <FormattedMessage id="joinTeam" />
          </LocalizedLink>
          <LocalizedLink
            to="/about"
            activeStyle={{ borderLeft: 'solid 3px #6F4A92', paddingLeft: 10 }}
          >
            <FormattedMessage id="aboutUs" />
          </LocalizedLink>
          {locale === 'en' ? (
            <Link to={location.pathname.replace('/en', '')} className={styles.navLink}>
              中文
            </Link>
          ) : (
            <Link to={'en'.concat(location.pathname)} className={styles.navLink}>
              En
            </Link>
          )}
        </Menu>
      </div>
    </div>
  </div>
)

export default injectIntl(Header)
