module.exports = {
  en: {
    path: 'en',
    locale: 'English',
    default: true
  },
  zh: {
    path: 'zh',
    locale: 'Chinese',
    // default: true
  }
}
